import { Alert, FlashbarProps, SelectProps } from '@amzn/awsui-components-react';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { logger } from 'src/analytics/KatalLogger';
import {
  currentBusinessGroupName,
  currentBusinessGroupShortDesc,
  currentDataClassificationId
} from 'src/features/business-group/businessGroupSelectors';
import { Status } from 'src/models/AuthContextModels';
import { POTaggingEntity } from 'src/models/POTaggingModel';
import { XptReportExportFileDetails } from 'src/models/XptReportingModels';
import { fetchAndCombineS3Data } from 'src/utils/aws-s3-multi-part-request';
import { getAllObjectKeysFromS3 } from 'src/utils/aws-s3-services';
import { getActualsS3BucketName } from 'src/utils/xpt-s3-bucket-details';
import { ReportGrid } from '../XptReportGrid';
import { actualsColumnDefinition, filterStringsBasedOnSubstrings } from './XptActualsReportConfig';
import { eErrorMessages } from 'src/constants/generic-constants';
import { convertUTCtoLocalISO } from 'src/utils/date-time-utilities';

interface XptActualsReportGridProps {
  selectedCostCenter: SelectProps.Option | null;
  notifyFlashMessage: (content: string, flashBarType: FlashbarProps.Type, isDismissible: boolean, messageId?: string) => void;
}

export const XptActualsReportGrid: React.FC<XptActualsReportGridProps> = ({ selectedCostCenter, notifyFlashMessage }) => {
  const dataClassificationId = useSelector(currentDataClassificationId);
  const dataClassificationShortDesc = useSelector(currentBusinessGroupShortDesc);
  const businessGroupName = useSelector(currentBusinessGroupName);

  const bucketName = getActualsS3BucketName().bucketName;

  const [initializingActualsDataS3Keys, setInitializingActualsDataS3Keys] = useState<Status>(Status.NotInitiated);
  const [actualsDataS3Keys, setActualsDataS3Keys] = useState<string[]>([]);

  const [initializingGridData, setInitializingGridData] = useState<Status>(Status.NotInitiated);
  const [actualsData, setActualsData] = useState<POTaggingEntity[]>([]);
  const [actualsColDefinitions, setActualsColDefinitions] = useState<ColDef[]>([]);

  const [exportFileDetails, setExportFileDetails] = useState<XptReportExportFileDetails>();

  const gridStateKey = `UniqueGridStateKey-XptActualsReport-${dataClassificationShortDesc}-v2`;
  const gridRef = useRef<AgGridReact>(null);

  useEffect(() => {
    setActualsColDefinitions(actualsColumnDefinition());
  }, []);

  const updateFileDetails = useCallback(() => {
    const fileDetails: XptReportExportFileDetails = {
      fileName: `Actuals_Report_${dataClassificationShortDesc}_${selectedCostCenter?.label}`,
      sheetName: `Actuals_Report_${dataClassificationShortDesc}_${selectedCostCenter?.label}`
    };
    setExportFileDetails(fileDetails);
  }, [dataClassificationShortDesc, selectedCostCenter, setExportFileDetails]);

  const initializeS3Keys = useCallback(async () => {
    if (!dataClassificationId || !dataClassificationShortDesc) return;

    try {
      setInitializingActualsDataS3Keys(Status.Loading);
      const s3KeyPrefix = `${dataClassificationShortDesc}_${dataClassificationId}/`;
      const allKeys = await getAllObjectKeysFromS3(bucketName, s3KeyPrefix);
      const actualsDataOnlyKeys: string[] = allKeys.filter((key) => !key.includes('mutation') && key.endsWith('list_actuals_data.json'));
      setActualsDataS3Keys(actualsDataOnlyKeys);
      if (actualsDataOnlyKeys.length === 0) {
        notifyFlashMessage(`No data available for this business group, ${eErrorMessages.GENERIC_ERROR_WITH_TICKET_INFO}`, 'error', true);
      }
      setInitializingActualsDataS3Keys(Status.Completed);
    } catch (error: any) {
      logger.error('Error while fetching s3 keys:', error);
      notifyFlashMessage(`Unable to load data for this business group, ${eErrorMessages.GENERIC_ERROR_WITH_TICKET_INFO}`, 'error', true);
      setInitializingActualsDataS3Keys(Status.Failed);
    }
  }, [dataClassificationId, dataClassificationShortDesc, bucketName, notifyFlashMessage]);

  useEffect(() => {
    initializeS3Keys();
  }, [initializeS3Keys]);

  const getDataFromS3 = useCallback(async () => {
    if (!selectedCostCenter || actualsDataS3Keys.length === 0) return;

    const selectedCC: string = selectedCostCenter.value || '';
    clearGridState();
    setInitializingGridData(Status.Loading);
    const seKeysForSelectedCCs = filterStringsBasedOnSubstrings(actualsDataS3Keys, [selectedCC]);

    if (seKeysForSelectedCCs.length === 0) {
      setInitializingGridData(Status.Failed);
      notifyFlashMessage(`No data available for Cost Center '${selectedCC}', ${eErrorMessages.GENERIC_ERROR_WITH_TICKET_INFO}`, 'error', true);
      return;
    }

    try {
      logger.info(`Fetching Actuals Data for Cost Center '${selectedCC}' from S3`);
      const { combinedData, results } = await fetchAndCombineS3Data(bucketName, seKeysForSelectedCCs);
      const combinedDataParsedWithTimeStamp = combinedData.map((item) => ({
        ...item,
        updated_at: item.updated_at ? convertUTCtoLocalISO(item.updated_at) : ''
      }));

      logger.info(`Total combined Actuals data for Cost Center '${selectedCC}' : ${combinedDataParsedWithTimeStamp.length}`);

      if (combinedDataParsedWithTimeStamp.length > 70000) {
        logger.warn(`Total combined Actuals data for Cost Center '${selectedCC}' is greater than 70,000 records. Warning alert`);
      }

      let hasError = false;
      results.forEach((result) => {
        if (!result.data) {
          hasError = true;
          logger.error(`Failed to fetch Actuals Data from ${result.key}: ${result.error}`);
        }
      });

      if (hasError) {
        notifyFlashMessage(
          `Failed to fetch complete Actuals Data for Cost Center '${selectedCC}'. Some of the months might be missing. Reach out to support for further assistance.`,
          'error',
          true
        );
      }

      setActualsData(combinedDataParsedWithTimeStamp);
      setInitializingGridData(Status.Completed);
    } catch (error: any) {
      logger.error('Error fetching and combining Actuals S3 data:', error);
      notifyFlashMessage(`Unable to load Actuals Data for Cost Center '${selectedCC}'. Reach out to support for further assistance.`, 'error', true);
      setInitializingGridData(Status.Failed);
    }
  }, [selectedCostCenter, actualsDataS3Keys, bucketName, notifyFlashMessage]);

  const clearGridState = () => {
    setActualsData([]);
  };

  useEffect(() => {
    if (selectedCostCenter) {
      updateFileDetails();
      getDataFromS3();
    }
  }, [selectedCostCenter, getDataFromS3]);

  const reloadActualsReportData = () => {
    getDataFromS3();
  };

  return (
    <>
      {initializingGridData === Status.Failed && <Alert type="error">{`Unable to load Actuals Report. Please reach out to support.`}</Alert>}
      {initializingGridData !== Status.Failed && (
        <ReportGrid
          gridRef={gridRef}
          columnDefs={actualsColDefinitions}
          rowData={actualsData}
          uniqueIdField={`actuals_item_id`}
          isLoading={initializingGridData === Status.Loading}
          localStorageKey={gridStateKey}
          refreshReport={reloadActualsReportData}
          exportFileDetails={exportFileDetails}
          notificationMessage={notifyFlashMessage}
          showPivotModeOptionInPanel={true}
          enablePivotMode={false}
          page="Actuals Report"
          mainPage={businessGroupName}
        />
      )}
    </>
  );
};
