import { BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import { CellClassParams, ColDef, ValueFormatterParams, ValueParserParams } from 'ag-grid-community';
import { cellValueParser, poTaggingCurrencyFormatter } from 'src/utils/ag-grid-utils';
import { getSuffixColumns } from '../XptReportColumnGenerator';

export const getXptActualsReportsBreadcrumbItems = (
  businessGroupBaseBreadcrumbs: BreadcrumbGroupProps.Item[],
  currentBusinessGroupName?: string
): BreadcrumbGroupProps.Item[] => {
  if (!currentBusinessGroupName) {
    return businessGroupBaseBreadcrumbs;
  }

  return [
    ...businessGroupBaseBreadcrumbs,
    {
      text: 'Actuals Report',
      href: `/${currentBusinessGroupName}/actuals-report`
    }
  ];
};

export const filterStringsBasedOnSubstrings = (strings: string[], substrings: string[]): string[] => {
  return strings.filter((str) => substrings.some((substring) => str.includes(substring)));
};

const monthOrder = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

const customMonthPivotComparator = (a: string, b: string): number => {
  const [monthA, yearA] = a.split('-');
  const [monthB, yearB] = b.split('-');

  // Compare years
  const yearDiff = parseInt(yearA) - parseInt(yearB);
  if (yearDiff !== 0) {
    return yearDiff;
  }

  // If years are the same, compare months
  return monthOrder.indexOf(monthA) - monthOrder.indexOf(monthB);
};

export const actualsColumnDefinition = (): ColDef[] => {
  const columnDefs: ColDef[] = [
    {
      headerName: 'Actual Item ID',
      field: 'actuals_item_id',
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true
    },
    {
      headerName: 'PO #',
      field: 'po_number',
      width: 130,
      minWidth: 130,
      hide: false,
      sort: 'asc', // Default sort direction
      sortIndex: 0, // Sort order priority
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'PO Line #',
      wrapHeaderText: true,
      field: 'po_line_number',
      width: 130,
      minWidth: 130,
      hide: false,
      sort: 'asc', // Default sort direction
      sortIndex: 1, // Sort order priority
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Line Item ID',
      wrapHeaderText: true,
      field: 'xpt_line_item_id',
      width: 155,
      minWidth: 155,
      hide: false,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Month',
      field: 'period_name',
      hide: false,
      width: 120,
      minWidth: 120,
      pivot: true, // Enable pivoting on this column
      enableValue: false,
      pivotComparator: (a: string, b: string) => customMonthPivotComparator(a, b),
      comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isDescending: boolean) => {
        // The comparator should always return the same order, regardless of isDescending
        // ag-grid will handle the descending order internally
        return customMonthPivotComparator(valueA, valueB);
      }
    },
    {
      headerName: 'Monthly Actual Spent (USD)',
      wrapHeaderText: true,
      field: 'entered_amount_usd',
      valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
      valueFormatter: (params: ValueFormatterParams) => poTaggingCurrencyFormatter(params.value),
      hide: false,
      width: 170,
      minWidth: 170,
      aggFunc: 'sum',
      enableValue: true,
      enableRowGroup: false,
      enablePivot: false,
      cellClassRules: {
        'bold-text': (params: CellClassParams) => params.node.footer || false
      }
    },
    // PO Line Approved Amount is excluded from aggregation
    // This is because aggregating line approved amounts across multiple PO numbers
    // would not provide meaningful data in the context of this grid.
    {
      headerName: 'PO Line Approved Amount (USD)',
      wrapHeaderText: true,
      field: 'po_line_approved_amt_usd',
      valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
      valueFormatter: (params: ValueFormatterParams) => poTaggingCurrencyFormatter(params.value),
      hide: false,
      width: 150,
      minWidth: 150,
      enableValue: false, // disabling from value
      enablePivot: false,
      enableRowGroup: true,
      cellClassRules: {
        'bold-text': (params: CellClassParams) => params.node.footer || false
      }
    },
    // PO Header Approved Amount is excluded from aggregation
    // This is because aggregating header amounts across multiple PO numbers
    // would not provide meaningful data in the context of this grid.
    {
      headerName: 'PO Header Approved Amount (USD)',
      wrapHeaderText: true,
      field: 'po_header_approved_amt_usd',
      valueParser: (params: ValueParserParams) => cellValueParser(params.newValue),
      valueFormatter: (params: ValueFormatterParams) => poTaggingCurrencyFormatter(params.value),
      hide: false,
      width: 150,
      minWidth: 150,
      enableValue: false, // disabling from value
      enablePivot: false,
      enableRowGroup: true,
      cellClassRules: {
        'bold-text': (params: CellClassParams) => params.node.footer || false
      }
    },
    {
      headerName: 'PO Need By Date',
      wrapHeaderText: true,
      field: 'po_need_by_date',
      hide: false,
      width: 130,
      minWidth: 130,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'PO Requestor',
      wrapHeaderText: true,
      field: 'po_requestor',
      hide: false,
      width: 130,
      minWidth: 130,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Line Description',
      wrapHeaderText: true,
      field: 'po_line_description',
      hide: true,
      width: 150,
      minWidth: 150,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'JE Category',
      wrapHeaderText: true,
      field: 'je_category',
      cellClass: ['text-field'],
      hide: false,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Status',
      field: 'status',
      hide: false,
      width: 120,
      minWidth: 120,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Cost Center Code',
      wrapHeaderText: true,
      field: 'cost_center_code',
      cellClass: ['text-field'],
      hide: true,
      width: 120,
      minWidth: 120,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Cost Center Description',
      wrapHeaderText: true,
      field: 'cost_center_description',
      cellClass: ['text-field'],
      hide: true,
      width: 170,
      minWidth: 170,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Account Code',
      wrapHeaderText: true,
      field: 'account_code',
      cellClass: ['text-field'],
      hide: false,

      width: 120,
      minWidth: 120,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Account Description',
      wrapHeaderText: true,
      field: 'account_description',
      cellClass: ['text-field'],
      hide: false,
      width: 170,
      minWidth: 170,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Product Code',
      wrapHeaderText: true,
      field: 'product_code',
      cellClass: ['text-field'],
      hide: false,
      width: 120,
      minWidth: 120,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Product Description',
      wrapHeaderText: true,
      field: 'product_description',
      cellClass: ['text-field'],
      hide: false,
      width: 170,
      minWidth: 170,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Project Code',
      wrapHeaderText: true,
      field: 'project_code',
      cellClass: ['text-field'],
      hide: false,
      width: 120,
      minWidth: 120,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Project Description',
      wrapHeaderText: true,
      field: 'project_description',
      cellClass: ['text-field'],
      hide: false,
      width: 170,
      minWidth: 170,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Channel Code',
      wrapHeaderText: true,
      field: 'channel_code',
      cellClass: ['text-field'],
      hide: true,
      width: 120,
      minWidth: 120,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Channel Description',
      wrapHeaderText: true,
      field: 'channel_description',
      cellClass: ['text-field'],
      hide: true,
      width: 170,
      minWidth: 170,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Company Code',
      wrapHeaderText: true,
      field: 'company_code',
      cellClass: ['text-field'],
      hide: true,
      width: 120,
      minWidth: 120,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Company Description',
      wrapHeaderText: true,
      field: 'company_description',
      cellClass: ['text-field'],
      hide: true,
      width: 170,
      minWidth: 170,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Location Code',
      wrapHeaderText: true,
      field: 'location_code',
      cellClass: ['text-field'],
      hide: true,
      width: 120,
      minWidth: 120,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    {
      headerName: 'Location Description',
      wrapHeaderText: true,
      field: 'location_description',
      cellClass: ['text-field'],
      hide: true,
      width: 170,
      minWidth: 170,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: false
    },
    ...getSuffixColumns()
  ];

  return columnDefs;
};
